import {
	Module,
	Mutation,
	Action,
	VuexModule,
	getModule,
	MutationAction,
} from "vuex-module-decorators";
import axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import { socket } from "@/main";
import { env } from "@/config/index";
import { Module as Mod } from "vuex";
import { IRootState, store } from "@/store";
import { IToken, Session } from "types/store";
import { callCenterClient, dashboardClient } from "@/conection/index";
import { AuthStatus, GuardRoles } from "types/router";
import { AlertModule } from "./Alert";
import { parseResponse } from "@/util/data";
import { IStatus, PauseModule } from "./Pause";
import { TicketModule } from "./Ticket";
import { NotificationModule } from "./Notification";
import { alertMessages } from "@/util/consts";
import { CallDataModule } from "./CallData";
import { PhoneModule } from "./Phone";
import { ElMessageBox } from "element-plus";
import { AppModule } from "./app";
import { OutputDeviceModule } from "./OutputDevice";
const coreApi = axios.create({ baseURL: env.CoreApi });

export interface IError {
	message: string;
}
export interface IAuth {
	loading: boolean;
	verifiedToken: boolean;
	status: AuthStatus;
	data: {
		user?: IToken;
		accessToken?: string;
		extension?: string;
		saved?: boolean | undefined;
	};
	admin: {
		username: string;
		password: string;
	};
	role: GuardRoles;
	inactive: boolean;
	pjsipPass: string | null;
}

export interface ILoginForm {
	username: string;
	password: string;
	extension?: string;
	loginCallCenter: boolean;
}

enum AuthFetch {
	LOGIN = "login",
	REFRESH = "refresh",
	RECONNECT = "reconnect",
}

/**
 * Obtiene total de llamadas en cola
 */
function getQueuesInCall() {
	dashboardClient.post("/backendApi/api/queue/calls");
}

@Module({ dynamic: true, store, name: "auth", namespaced: true })
export class Auth extends VuexModule implements IAuth {
	constructor(module: Mod<ThisType<IAuth>, IRootState>) {
		super(module);
		// Load at init accesstoken if exists in localstorage
		this.AccessToken(localStorage.getItem("ACCESS_TOKEN") ?? "");
		this.setExtension(localStorage.getItem("CALL_EXTENSION") ?? "");
		this.setPjsipPass(localStorage.getItem("PJSIP_PASS") ?? "");
	}

	data: IAuth["data"] = {};
	timer: NodeJS.Timeout;
	admin = {
		username: env.adminUsername || "",
		password: env.adminPassword || "",
	};
	_status: AuthStatus = AuthStatus.LOADING_NONE;
	loading = false;
	verifiedToken = false;
	_method: AuthFetch = AuthFetch.LOGIN;
	ierror?: IError;
	_inactive = false;
	pjsipPass: string | null = "";
	get token() {
		return this.data.accessToken;
	}

	get extension() {
		return this.data.extension;
	}
	get code() {
		return this.data.user?.code;
	}
	get callRole() {
		return this.data.extension && this.verifiedToken
			? GuardRoles.CALLAGENT
			: GuardRoles.GUEST;
	}
	get role() {
		return this.data.accessToken && this.verifiedToken
			? GuardRoles.AGENT
			: GuardRoles.GUEST;
	}
	get error() {
		return this.ierror?.message ?? undefined;
	}

	get status() {
		return this._status;
	}

	get isAuthenticated() {
		const ttl: number =
			new Date((jwt.decode(this.token)?.exp ?? 0) * 1000).valueOf() -
			Date.now();
		if (!this.token || ttl < 0) return Session.INVALID;
		if (!this.verifiedToken) return Session.UNVERIFIED;
		return Session.VERIFIED;
	}

	get id(): string {
		return this.data.user?.id ?? "";
	}

	get organizationID(): string {
		return this.data.user?.organization._id ?? "";
	}

	get user(): IToken | undefined {
		return this.data.user;
	}

	get inactive(): boolean {
		return this._inactive;
	}

	get Admin() {
		return this.admin;
	}
	get FullAuth() {
		return this.status == AuthStatus.FULL_AUTHENTICATED;
	}
	get getPjsipPass() {
		return this.pjsipPass;
	}
	@Mutation
	setActivity(b: boolean) {
		this._inactive = b;
	}

	@Mutation
	setFetchMethod(payload: AuthFetch) {
		this._method = payload;
	}

	@Mutation
	AccessToken(token: string | undefined) {
		if (!token) localStorage.removeItem("ACCESS_TOKEN");
		this.data.accessToken = token;
	}

	@Mutation
	Loading(loading: boolean) {
		this.loading = loading;
	}

	@Mutation
	VerifiedToken(verifiedToken: boolean) {
		this.verifiedToken = verifiedToken;
	}

	@Mutation
	setStatus(status: AuthStatus) {
		this._status = status;
	}

	@Mutation
	setExtension(ext: string | undefined) {
		if (!ext) localStorage.removeItem("CALL_EXTENSION");
		this.data.extension = ext;
	}

	@Mutation
	setUser(user: Partial<IToken>) {
		this.data.user = Object.assign(this.data.user ?? {}, user) as IToken;
	}

	@Mutation
	resetError() {
		this.ierror = undefined;
	}

	@Mutation
	SOCKET_AGENT_AUTH_FAILED() {
		this.loading = false;
		this._status = AuthStatus.LOADING_NONE;
		this.ierror = {
			message:
				"<strong>Error!</strong><br /> Usuario  o contraseña incorrectas",
		};
	}
	@Mutation
	setSaved(saved: boolean) {
		this.data.saved = saved;
	}
	@Mutation
	setPjsipPass(password: string | null) {
		if (!password) localStorage.removeItem("PJSIP_PASS");
		this.pjsipPass = password;
	}

	// Se ejecuta cuando se reconecta al sistema (recarga de pestaña, reconexión de red, etc)
	@MutationAction
	async validateToken(payload: {
		token: string;
		listAll: boolean;
		extension?: string;
	}) {
		const { token, extension, ...data } = payload;
		coreApi.defaults.headers.common["access-token"] = token;
		coreApi.defaults.headers.common["access-extension"] = this.data.extension
			? this.data.extension
			: extension ?? "";
		const response = await coreApi.post("api/auth/user/save", {
			socketId: socket.id,
			...data,
		});
		const verifiedToken = response.data.success;
		if (env.GetQueueIncoming && verifiedToken && !this.data.saved)
			getQueuesInCall();
		if (verifiedToken) {
			AuthModule.setSaved(true);
			AuthModule.tokenRefresh(response.data.data);
		} else if (response.data.duplicated) {
			AuthModule.sessionDuplicate();
		} else {
			AuthModule.logout();
			AuthModule.agentAuthLogout();
			socket.disconnect();
		}
		return {
			verifiedToken,
		};
	}

	@Action
	async apiLogin({
		username,
		password,
		extension,
		loginCallCenter,
	}: ILoginForm) {
		this.setFetchMethod(AuthFetch.LOGIN);
		PauseModule.SOCKET_SET_LISTO();
		this.setPjsipPass(password);
		this.Loading(true);
		this.setStatus(AuthStatus.LOADING_CHAT);
		localStorage.setItem(
			"CALL_EXTENSION",
			loginCallCenter && extension ? extension : ""
		);
		localStorage.setItem("PJSIP_PASS", password);
		this.setExtension(loginCallCenter ? extension : undefined);
		const response = await coreApi.post("api/auth/user/login", {
			organization: env.Organization,
			username,
			password,
			extension,
			socketId: socket.id,
		});

		AlertModule.SOCKET_PUSH_NOTIFICATION({
			type: response.data.success ? "success" : "error",
			text: response.data.message,
		});
		if (response.data.success) {
			this.tokenRefresh(response.data.data);
		} else {
			this.Loading(false);
			this.setStatus(AuthStatus.LOADING_NONE);
		}
	}

	@Action
	setExtensionLocalStorage(extension: string) {
		localStorage.setItem("CALL_EXTENSION", extension ?? "");
	}
	@Action
	loginChatCenter({
		token,
		user,
		listAll,
	}: {
		token: string;
		user: IToken;
		listAll: boolean;
	}) {
		localStorage.setItem("ACCESS_TOKEN", token);
		this.AccessToken(token);
		this.Loading(false);
		this.VerifiedToken(true);
		this.setUser(user);
		if (listAll && !socket.connected) socket.connect();
	}
	@Action
	fetchOmnichannelData({ token }: { token: string }) {
		TicketModule.requestListTickets(token);
		socket.emit("PAUSAS_LIST", { token });
		socket.emit("ACCOUNT_LIST", { token });
		socket.emit("TIPIFICACION_LIST", { token });
	}

	@Action
	async loginCallCenter(code: string | undefined) {
		console.log("LoginCallcenter");
		console.log(env.requiredCallcenter);

		//Validar que el callcenter sea requerido
		if (!env.requiredCallcenter) {
			this.setStatus(AuthStatus.CHAT_AUTHENTICATED);
			this.setSaved(false);
			return;
		}

		//Verificación de la existencia del codigo en la base de datos de mongo
		if (!code) {
			this.setStatus(AuthStatus.CHAT_AUTHENTICATED);
			if (this.extension)
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.NOT_CODE,
					type: "warning",
				});
			this.setSaved(false);
			return;
		}
		try {
			if (env.GetQueueIncoming) getQueuesInCall(); // para que en todo lo que inicie en call center obtengo el total de llamadas en cola
			const response: AxiosResponse = await callCenterClient({
				method: "post",
				url: "controllers/auth.php",
				data: new URLSearchParams({
					agente: code,
					exten: this.extension ?? "",
					state: env.readyOnLoginCallCenter ? "0" : "1",
				}),
			});
			const res = parseResponse(response);
			if (res.errorCode) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: res.message,
					type: "error",
				});
				this.setStatus(AuthStatus.CHAT_AUTHENTICATED);
				this.setSaved(false);
				return;
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: `CallCenter: ${res.message}`,
					type: "success",
				});
				this.setSaved(true);
				this.setStatus(AuthStatus.FULL_AUTHENTICATED);

				PauseModule.updateAgentStatus();
				CallDataModule.updateActiveCall();
				if (env.remoteLogin) {
					window.open(
						env.remoteLoginFronted +
							"?ext=" +
							this.extension +
							"&token=" +
							this.token,
						"_blank"
					);
					console.log("remoteLoginFronted", env.remoteLoginFronted);
				}
				OutputDeviceModule.testOutputDevice();
				return;
			}
		} catch (error) {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: `${error}`,
				type: "error",
			});
			this.setStatus(AuthStatus.CHAT_AUTHENTICATED);
			console.error(error, "error");
			this.setSaved(false);
			if (env.onlyCallcenter) this.logout();
		}
	}
	/**
	 * Funcion for same session duplicate
	 * Executed by websocket via SESSION_DUPLICATE
	 */
	@Action
	async sessionDuplicate() {
		console.log("Session duplicate executed!");
		this.softCallCenterLogout({ resetExtension: false });
		PhoneModule.endSipSession();
		clearTimeout(this.timer);
		this.setStatus(AuthStatus.LOADING_NONE);
		ElMessageBox.alert(
			"Ya posee una sesión abierta en otra pestaña",
			"Sesión duplicada detectada",
			{
				confirmButtonText: "Usar aquí",
				callback: () => window.location.reload(),
			}
		);
	}
	// Action for socket event on duplicate session
	@Action
	async agentDuplicate() {
		await PauseModule.pauseAgent("1");
		await this.logout();
		ElMessageBox.alert(
			"Se ha abierto una nueva sesión en otro navegador, no puede utilizar dos sesiones al mismo tiempo!",
			"Sesión duplicada detectada",
			{
				confirmButtonText: "Regresar al login",
				callback: () => (window.location.href = "/login"),
			}
		);
	}

	// Action for socket event on SUPERVISOR_LOGOUT
	@Action
	async supervisorLogout() {
		await PauseModule.pauseAgent("1");
		clearTimeout(this.timer);
		console.group("Auth");
		console.log(this.status);
		console.log(this.extension);
		console.log(this.status == AuthStatus.FULL_AUTHENTICATED && this.extension);
		console.groupEnd();

		if (this.status == AuthStatus.FULL_AUTHENTICATED && this.extension) {
			PhoneModule.endSipSession();
		}
		this.setStatus(AuthStatus.LOADING_NONE);
		this.AccessToken(undefined);
		this.VerifiedToken(false);
		ElMessageBox.alert(
			"El supervisor ha cerrado la sesión de manera remota",
			"Sesión cerrada por supervisor",
			{
				confirmButtonText: "Regresar al login",
				callback: () => (window.location.href = "/login"),
				type: "warning",
			}
		);
	}
	@Action
	async logout() {
		console.log("Aqui1");
		console.log("this.extension", this.extension);

		if (this.status == AuthStatus.FULL_AUTHENTICATED)
			if (PauseModule.data[IStatus.CALLCENTER].isPaused != true) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.NOT_PAUSE,
					type: "error",
				});
				return;
			} else {
				if (this.extension) {
					const isLogout = await this.logoutCallCenter();
					if (!isLogout) return;

					PhoneModule.endSipSession();
				}
			}
		clearTimeout(this.timer);
		socket.emit("AGENT_AUTH_LOGOUT", {
			token: AuthModule.token,
		});
	}

	@Action
	async agentAuthLogout() {
		clearTimeout(this.timer);
		console.group("Auth");
		console.log(this.status);
		console.log(this.extension);
		console.log(
			this.status == AuthStatus.FULL_AUTHENTICATED && this.extension != ""
		);
		console.groupEnd();

		if (this.status == AuthStatus.FULL_AUTHENTICATED && this.extension) {
			PhoneModule.endSipSession();

			await this.logoutCallCenter();
		}
		this.setStatus(AuthStatus.LOADING_NONE);
		this.AccessToken(undefined);
		this.VerifiedToken(false);
	}
	/**
	 * Acción para hacer el logout del callcenter, en este caso
	 * si se realiza correctamente regresa true, sino regresa false
	 * @param config
	 * @returns boolean, se retorna un valor boleano
	 */
	@Action
	async logoutCallCenter(
		config: { resetExtension: boolean } = { resetExtension: true }
	): Promise<boolean> {
		try {
			const response: AxiosResponse = await callCenterClient({
				method: "post",
				url: "controllers/setCloseSession.php",
				data: new URLSearchParams({
					extension: AuthModule.extension ?? "",
					agente: AuthModule.code ?? "",
				}),
			});
			const res = parseResponse(response);
			if (res.status === "Error") {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: res.message,
					type: "warning",
				});
				this.setSaved(false);
				return false;
			} else {
				//SendLogout ISBM
				if (env.remoteLogin) {
					axios.post(
						`${env.remoteLoginBackend}/auth/logout`,
						{ code: this.code, extension: this.extension },
						{
							auth: {
								username: env.remoteLoginUsername,
								password: env.remoteLoginPassword,
							},
						}
					);
				}

				PhoneModule.endSipSession();
				if (config.resetExtension) this.setExtension(undefined);
				this.setSaved(false);
				this.setPjsipPass(null);
				PauseModule.setLoadedPause(false);
				PauseModule.setFetched(false);
				return true;
			}
		} catch (error) {
			console.error(error, "error");
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: "Error desconocido, llame a soporte",
				type: "error",
			});
			return false;
		}
	}
	/**
	 * Funcion realizada para sacar al agente desde el frontend pero no del backend
	 * siempre estara loggeado en el DMS, se realiza cuando se tira el evento
	 * sesión duplicada SESSION_DUPLICATE
	 */
	@Action
	softCallCenterLogout(
		config: { resetExtension: boolean } = { resetExtension: true }
	) {
		PhoneModule.endSipSession();
		if (config.resetExtension) this.setExtension(undefined);
		this.setSaved(false);
		// this.setPjsipPass(null); // no debe eliminar la password del localstorage
		return true;
	}

	@Action
	tokenRefresh({ token, listAll }: { token: string; listAll: boolean }) {
		console.log("HACE TOKEN UPDATE");
		const user: IToken = jwt.decode(token);

		coreApi.post(
			"/api/log",
			{
				user,
				agent: this.user?.code?.toString() ?? this.user?.name,
			},
			{
				headers: {
					"access-token": token,
				},
			}
		);
		const ttl: number =
			new Date((user ? user.exp : 0) * 1000).valueOf() - Date.now();
		if (ttl < 0) return;
		this.timer = setTimeout(() => {
			const verifiedTtl =
				new Date((user ? user.exp : 0) * 1000).valueOf() - Date.now();
			if (verifiedTtl > 2)
				this.validateToken({
					token,
					listAll: false,
					extension: AuthModule.extension,
				});
			else this.agentAuthLogout();
		}, ttl * 0.9);

		this.setStatus(
			this.extension ? AuthStatus.LOADING_CALL : AuthStatus.CHAT_AUTHENTICATED
		);
		this.loginChatCenter({ token, user, listAll });
		if (this.extension) {
			if (this.data.saved) {
				this.setStatus(AuthStatus.FULL_AUTHENTICATED);
			} else {
				this.loginCallCenter(user.code);
			}
		}
	}

	@Action
	async connect() {
		console.log("********* CONNECT / WEBSOCKET ********");
		if (this._method === AuthFetch.RECONNECT) {
			if (this.timer) clearTimeout(this.timer);
			await this.validateToken({ token: this.token ?? "", listAll: true });
		}
		if (this.token && this.isAuthenticated === Session.VERIFIED) {
			await coreApi.post("api/auth/user/connect", {
				socketId: socket.id,
				token: this.token,
				organization: this.user?.organization._id ?? env.Organization,
				method: this._method,
				extension: this.extension,
			});
			/* 			socket.emit("INIT_AUTH", {
				organization: env.Organization,
				agentId: this.user?.id,
				listAll: true,
				method: this._method,
				extension: this.extension,
			}); */
			NotificationModule.fetchNotifications({ token: this.token ?? "" });
			this.fetchOmnichannelData({ token: this.token ?? "" });
		} /* else {
			this.logout();
			this.agentAuthLogout();
			socket.disconnect();
		} */
	}

	@Action
	pingOnline() {
		socket.emit("PONG_ONLINE");
	}

	@Action
	disconnect() {
		console.log("********* DISCONNECT / WEBSOCKET ********");
		this.setFetchMethod(AuthFetch.RECONNECT);
		/* 		AlertModule.SOCKET_PUSH_NOTIFICATION({
			type: "warning",
			text: "Problemas con la conexión, reintentando conectarse...",
		}); */
	}

	@Action
	async saveSession(): Promise<IToken | undefined> {
		if (!this.token) return undefined;
		switch (this.isAuthenticated) {
			case Session.UNVERIFIED: {
				try {
					console.log("********* SAVE / SESSION / UNVERIFIED ********");
					this.setFetchMethod(AuthFetch.REFRESH);
					await this.validateToken({ token: this.token, listAll: true });
					const user: IToken | undefined = this.verifiedToken
						? jwt.decode(this.token)
						: undefined;

					if (this.verifiedToken && user) {
						// if (env.DynamicUser || env.DynamicTypification)
						await AppModule.getConfig({
							organization: user?.organization?._id ?? "",
						});
						this.setUser(user);
					}
					return user;
				} catch (error) {
					console.error(error);
					return undefined;
				}
			}
			case Session.VERIFIED: {
				const user: IToken = this.data.user || jwt.decode(this.token);
				if (!this.data.user) this.context.commit("setUser", user);
				if (
					// (env.DynamicUser || env.DynamicTypification) &&
					!AppModule.configs.length
				)
					await AppModule.getConfig({
						organization: user.organization?._id ?? "",
					});
				return user;
			}
			case Session.INVALID:
				return undefined;
		}
	}
	@Action
	changeExtension(values: any) {
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			AuthModule.setStatus(AuthStatus.LOADING_CALL);
			if (PauseModule.data[IStatus.CALLCENTER].isPaused != true) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.NOT_PAUSE,
					type: "error",
				});
				AuthModule.setStatus(AuthStatus.FULL_AUTHENTICATED);

				return;
			} else {
				this.logoutCallCenter().then(() => {
					PhoneModule.endSipSession();
					this.loginExtCall(values);
					PhoneModule.setKey(PhoneModule.getKey + 1);
					CallDataModule.updateActiveCall();
				});
			}
		} else {
			AuthModule.setStatus(AuthStatus.LOADING_CALL);
			PhoneModule.setKey(PhoneModule.getKey + 1);
			CallDataModule.updateActiveCall();
			this.loginExtCall(values);
		}
	}
	@Action
	loginExtCall(values: any) {
		AuthModule.setExtension(values.Extension);
		AuthModule.setExtensionLocalStorage(values.Extension);
		AuthModule.loginCallCenter(AuthModule.code);
	}
}

export const AuthModule = getModule(Auth);
