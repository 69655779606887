import { PartialUser, ITypification, IChannel } from "@/callcenter/omni";
import { IAgent, IClient } from "./tickets";
import { IndexType } from "./store";

export enum CallDataStatus {
	PENDING = "pending",
	CLAIMED = "claimed",
	QUALIFIED = "qualified",
}
export interface IActiveMongoCall {
	_id: string;
	agent: IAgent | string;
	callType: string;
	phone_number: string;
	uniqueid: string;
	queue: string;
	data: IndexType<any>;
	data2: IndexType<any>;
	status: CallDataStatus;
	dms_call_date: Date;
	client: IClient;
}
export interface ICallData {
	ActiveCall: IActiveCall;
	Scoring: boolean; //Verifica si se esta evaluando una llamada
	fetched: boolean; //Sí ya empezo el polling
	User: PartialUser | undefined; //Usuario presentado en el cliente, form y card
	IsNewUser: boolean; //Bandera , dice si es un nuevo usuario
	LoadingSK: boolean;
	Typification: ITypification[] | undefined;
	TypificationFetched: boolean;
	Channels: IChannel[] | undefined;
	ChannelsFetched: boolean;
	ErrorAlert: boolean;
	saveCallType: ISaveCallType;
	enableControls: boolean;
	callDataCount: number;
	queueCalls: { [key: string]: number };
}
export interface IActiveCall {
	id: string;
	call_number: string;
	uniqueid: string;
	call_status?: CallDataStatus;
	resultado?: number;
	call_type: string;
	queue: string;
	has_active_form?: string;
}
export enum ISaveCallType {
	single = "single",
	saveCall = "saveCall",
	deleteCall = "deleteCall",
}
